import { apis } from "../../../../constants";
import { common_post } from "helpers";

export const apiGetDebtList = (dataRequest) => common_post(apis.get_debt_list, dataRequest)
export const apiGetUnpaidDebtList = (dataRequest) => common_post(apis.get_unpaid_debt_list, dataRequest)
export const apiGetPaidDebtList = (dataRequest) => common_post(apis.get_paid_debt_list, dataRequest)
export const apiPayDebt = (dataRequest) => common_post(apis.pay_debt, dataRequest)
export const apiGetRefundServiceList = (dataRequest) => common_post(apis.get_refund_service_list, dataRequest)
export const apiGetAdvanceMoney = (dataRequest) => common_post(apis.get_advance_money, dataRequest)
export const apiRefundMoney = (dataRequest) => common_post(apis.refund_money, dataRequest)
export const apiGetReceiptList = (dataRequest) => common_post(apis.get_receipts, dataRequest)
export const apiCancelPaidDebt = (dataRequest) => common_post(apis.cancel_paid_debt, dataRequest)

export const apiThanhToanCN = (dataRequest) => common_post(apis.vienphi_luu_hoa_don_thanh_toan_v2, dataRequest)
export const apiPhieuThuDv = (dataRequest) => common_post(apis.lay_ds_lich_su_phieu_thu, dataRequest)
