import { notification } from "antd";
import { cancelPaidDebt, debtManagementState, getAdvanceMoney, getAdvanceMoneySuccess, getDebtList, getDebtListSuccess, getListReceipts, getListReceiptsSuccess, getPaidDebt, getPaidDebtSuccess, getRefundServiceList, getRefundServiceListSuccess, getUnpaidDebt, getUnpaidDebtSuccess, payDebt, payDebtAndPrint, refundMoney, refundMoneyAndPrint } from "ducks/slices/QlyVienPhi/QlyCongNo.slice";
import { setButtonLoading, setModalLoading, setSiderLoading, setTableLoading } from "ducks/slices/loadingSlice";
import i18n, { languageKeys } from '../../../../i18n'
import { put, select, takeLatest } from "redux-saga/effects";

const { apiGetDebtList, apiGetUnpaidDebtList, apiGetPaidDebtList, apiPayDebt, apiGetRefundServiceList, apiGetAdvanceMoney, apiRefundMoney, apiGetReceiptList, apiCancelPaidDebt, apiThanhToanCN, apiPhieuThuDv } = require("./apiQlyCongNo");

export default function* watchDebtManagement() {
    yield takeLatest(getDebtList, handleGetDebtList)
    yield takeLatest(getUnpaidDebt, handleUnpaidDebtList)
    yield takeLatest(getPaidDebt, handlePaidDebtList)
    yield takeLatest(payDebt, handlePayDebt)
    yield takeLatest(getRefundServiceList, handleGetRefundServiceList)
    yield takeLatest(getAdvanceMoney, handleGetAdvanceMoney)
    yield takeLatest(refundMoney, handleRefundMoney)
    yield takeLatest(payDebtAndPrint, handlePayAndPrint)
    yield takeLatest(refundMoneyAndPrint, handleRefundMoneyAndPrint)
    yield takeLatest(getListReceipts, handleGetReceiptList)
    yield takeLatest(cancelPaidDebt, handleCancelPaidDebt)
}


function* handleGetDebtList({ payload }) {
    try {
        yield put(setSiderLoading(true))
        const res = yield apiGetDebtList(payload)

        if (!!res && res.status === 'OK') {
            yield put(getDebtListSuccess(res.result))
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    } finally {
        yield put(setSiderLoading(false))
    }
}

function* handleUnpaidDebtList({ payload }) {
    try {
        yield put(setTableLoading(true))
        const res = yield apiGetUnpaidDebtList({ HOA_DON_ID: payload.billId, LICH_KHAM_ID: payload.LICH_KHAM_ID })
        if (!!res && res.status === 'OK') {
            yield put(getUnpaidDebtSuccess(res.result[0]))
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    } finally {
        yield put(setTableLoading(false))
    }
}

function* handlePaidDebtList({ payload }) {
    try {
        yield put(setTableLoading(true))
        const res = yield apiGetPaidDebtList({ HOA_DON_ID: payload.billId, LICH_KHAM_ID: payload.LICH_KHAM_ID })
        if (!!res && res.status === 'OK') {
            yield put(getPaidDebtSuccess(res.result))
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    } finally {
        yield put(setTableLoading(false))
    }
}

function* handleGetRefundServiceList({ payload }) {
    try {
        yield put(setTableLoading(true))
        const res = yield apiGetRefundServiceList({ HOA_DON_ID: payload })
        if (!!res && res.status === 'OK') {
            yield put(getRefundServiceListSuccess(res.result))
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    } finally {
        yield put(setTableLoading(false))
    }
}

function* handlePayDebt({ payload }) {
    try {
        const { payload: dataPayload, callback } = payload
        yield put(setButtonLoading(true))
        const res = yield dataPayload.LICH_KHAM_ID ? apiThanhToanCN(dataPayload) : apiPayDebt(dataPayload)
        if (!!res) {
            if (res.status === 'OK') {
                callback(true)
                notification.success({
                    message: i18n.t(languageKeys.thao_tac_thanh_cong),
                    placement: 'bottomLeft'
                })
            } else if (res.status === 'KO' && res.error_code === "017") {
                notification.error({
                    message: i18n.t(languageKeys.dich_vu_da_thanh_toan),
                    placement: "bottomLeft",
                })
                callback(false)
            } else {
                notification.error({
                    message: i18n.t(languageKeys.thao_tac_that_bai),
                    placement: "bottomLeft",
                })
            }
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    } finally {
        put(setButtonLoading(false))
    }
}

function* handlePayAndPrint({ payload }) {
    try {
        const { keyPhieu, payload: dataPayload, callback } = payload
        yield put(setButtonLoading(true))
        const res = yield dataPayload.LICH_KHAM_ID ? apiThanhToanCN(dataPayload) : apiPayDebt(dataPayload)
        if (!!res) {
            if (res.status === 'OK') {
                callback({ arr_HOA_DON_ID: [res?.HOA_DON_ID], keyPhieu }, true)
                notification.success({
                    message: i18n.t(languageKeys.thao_tac_thanh_cong),
                    placement: 'bottomLeft'
                })
            } else if (res.status === 'KO' && res.error_code === "017") {
                notification.error({
                    message: i18n.t(languageKeys.dich_vu_da_thanh_toan),
                    placement: "bottomLeft",
                })
                callback({}, false)
            } else {
                notification.error({
                    message: i18n.t(languageKeys.thao_tac_that_bai),
                    placement: "bottomLeft",
                })
            }
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    } finally {
        put(setButtonLoading(false))
    }
}

function* handleGetAdvanceMoney({ payload }) {
    try {
        const res = yield apiGetAdvanceMoney({ BENH_NHAN_ID: payload })
        if (!!res && res.status === 'OK') {
            yield put(getAdvanceMoneySuccess({
                ...res.result,
                TOTAL_TAM_UNG: Number(res.result?.TOTAL_TAM_UNG)
            }))
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    }
}

function* handleRefundMoney({ payload }) {
    try {
        const { payload: payloadData, callback } = payload
        const res = yield payloadData.LICH_KHAM_ID ? apiThanhToanCN(payloadData) : apiRefundMoney(payloadData)
        if (!!res && res.status === "OK") {
            const { patientInfo } = yield select(debtManagementState)
            yield put(getPaidDebt(patientInfo))
            yield put(getUnpaidDebt(patientInfo))
            notification.success({
                message: i18n.t(languageKeys.thao_tac_thanh_cong),
                placement: 'bottomLeft'
            })
            callback()
        } else {
            notification.error({
                message: i18n.t(languageKeys.common_co_loi_xay_ra),
                placement: 'bottomLeft'
            })
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    } finally {
    }
}

function* handleRefundMoneyAndPrint({ payload }) {
    try {
        const { keyPhieu, payload: payloadData, callback } = payload
        const res = yield payloadData.LICH_KHAM_ID ? apiThanhToanCN(payloadData) : apiRefundMoney(payloadData)
        if (!!res && res.status === "OK") {
            const { patientInfo } = yield select(debtManagementState)
            yield put(getPaidDebt(patientInfo))
            yield put(getUnpaidDebt(patientInfo))
            callback({ arr_HOA_DON_ID: [res?.HOA_DON_ID], keyPhieu })
        } else {
            notification.error({
                message: i18n.t(languageKeys.common_co_loi_xay_ra),
                placement: 'bottomLeft'
            })
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra)
        })
    }
}

function* handleGetReceiptList({ payload }) {
    const { patientInfo } = yield select(debtManagementState)
    try {
        yield put(setModalLoading(true))
        const res = yield patientInfo.LICH_KHAM_ID ? apiPhieuThuDv(payload) : apiGetReceiptList(payload)
        if (!!res && res.status === 'OK') {
            yield put(getListReceiptsSuccess(res.result))
        }
    } catch (error) {

    } finally {
        yield put(setModalLoading(false))
    }
}

function* handleCancelPaidDebt({ payload }) {
    const { patientInfo } = yield select(debtManagementState)
    const { payLoad, callbackLoading, callbackClose, isModal } = payload
    try {
        callbackLoading(true)
        const res = yield apiCancelPaidDebt(payLoad)
        if (!!res && res.status === 'OK') {
            callbackClose()
            if (isModal) {
                yield put(getListReceipts({
                    HOA_DON_CHA_ID: patientInfo.billId,
                    PHAN_LOAI: 'CONG_NO',
                    BENH_NHAN_ID: patientInfo.ID,
                    LICH_KHAM_ID: patientInfo.LICH_KHAM_ID
                }))
                yield put(getPaidDebt(patientInfo))
            } else {
                yield put(getPaidDebt(patientInfo))
            }
            yield put(getUnpaidDebt(patientInfo))
            notification.success({
                message: i18n.t(languageKeys.thao_tac_thanh_cong),
                placement: 'bottomLeft'
            })
        } else {
            notification.error({
                message: i18n.t(languageKeys.thao_tac_that_bai),
                placement: 'bottomLeft'
            })
        }
    } catch (error) {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    } finally {
        callbackLoading(false)
    }
}