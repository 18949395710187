import { initializeApp } from "firebase/app";
import { getToken, getMessaging } from "firebase/messaging";

//Firebase Config values imported from .env file
const firebaseConfig = {
  apiKey: "AIzaSyAyfTSK5iNtDsxRtUcOeqdhh0NMh6AJXl8",
  authDomain: "deepcarecis.firebaseapp.com",
  projectId: "deepcarecis",
  storageBucket: "deepcarecis.appspot.com",
  messagingSenderId: "400343182554",
  appId: "1:400343182554:web:77bbd559d9cd4e99b249e3"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);

export const requestPermissionFb = async () => {
  //requesting permission using Notification API
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: 'BMQo9OpTlBkf8CgEpN66Y_6Aa_brmornfplDTjNz6ealgL4JndEUhbXwYDazCNk0p5xFco5FvpPyBJwiqGhApIY',
    });
    //We can send token to server
    console.log("Token generated : ", token);
    return token;
  } else if (permission === "denied") {
    //notifications are blocked
    alert("You denied for the notification");
  }
}