import { notification } from "antd";
import { getDetailData, getDetailDataSuccess, getDetailPackageUsageData, updateDetailData } from "ducks/slices/GoiDichVu/ThongTinSDGoiDv/detailPackageUsage.slice";
import { setTableLoading } from "ducks/slices/loadingSlice";
import i18n, { languageKeys } from "../../../../i18n";
import { put, select, takeLatest } from "redux-saga/effects";
import { layChiTietSdGoiDv, suaChiTietSdGoiDv } from "../apiGoiDichVu";
import { getServicePackageUsage, getServicePacketUsageData } from "ducks/slices/GoiDichVu/ThongTinSDGoiDv/serivcePackageUsage.slice";

export function* watchDetailServicePackageUsage() {
    yield takeLatest(getDetailData, handleFetchData);
    yield takeLatest(updateDetailData, handleUpdateData);
}

function* handleFetchData(action) {
    const userProfile = yield select(state => state.auth.user);
    const { BENH_NHAN_ID, ID, GOI_DV_ID, NHOM_GOI } = action.payload;
    try {
        yield put(setTableLoading(true));
        const res = yield layChiTietSdGoiDv({
            BENH_VIEN_ID: userProfile?.BENH_VIEN_ID,
            partner_code: userProfile?.partner_code,
            BENH_NHAN_ID,
            ID,
            GOI_DV_ID,
            NHOM_GOI,
        })
        if (!!res) {
            if (res.status === 'OK') {
                yield put(getDetailDataSuccess(res))
            } else {
                notification.error({
                    message: i18n.t(languageKeys.common_co_loi_xay_ra),
                    placement: 'bottomLeft'
                })
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.common_co_loi_xay_ra),
                placement: 'bottomLeft'
            })
        }
    }
    catch {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    }
    finally {
        yield put(setTableLoading(false))
    }
}

function* handleUpdateData(action) {
    const { data, callback } = action.payload
    try {
        yield put(setTableLoading(true));
        const res = yield suaChiTietSdGoiDv(data)
        if (!!res) {
            if (res.status === 'OK') {
                const { currentPage } = yield select(getServicePacketUsageData)
                yield put(getServicePackageUsage({
                    page: currentPage
                }))
                if (typeof callback === 'function') {
                    callback();
                }
            } else {
                notification.error({
                    message: i18n.t(languageKeys.common_co_loi_xay_ra),
                    placement: 'bottomLeft'
                })
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.common_co_loi_xay_ra),
                placement: 'bottomLeft'
            })
        }
    }
    catch {
        notification.error({
            message: i18n.t(languageKeys.common_co_loi_xay_ra),
            placement: 'bottomLeft'
        })
    }
    finally {
        yield put(setTableLoading(false))
    }
}